.landsec2{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 120px;
}

.landsec2title{
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    font-size: 60px;
}

.sec2boxes{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    margin-top: 150px;

}

.sec2box1cont{
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}


.sec2box1cont > img{
    width: 100%;
    max-width: 400px;
    height: auto;
}

.gogoleimage{
    position: absolute !important;
    width: 150px  !important;
    height: auto  !important;
    bottom: 50px;
    left: 30px;
    cursor: pointer;
}

.appleimage{
    position: absolute !important;
    width: 150px  !important;
    height: auto  !important;
    bottom: 120px;
    left: 30px;
    cursor: pointer;
}

.sec2price{
    margin-top: 130px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.sec2price > img{
    width: 25%;
}

.bigcards{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 130px;
}

.bigcards > img{
    margin-bottom: 80px;
    width: 50%;
    max-width: 650px;
}

.sec3text{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;
}

.sec3text > img{
    width: 30%;
}



.sec3textland{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: 100px; */
}

.sec3textland > img{
    width: 30%;
}

.reportbtn{
    cursor: pointer;
    width: 15% !important;
    margin-top: 80px;

}

.man{
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 160px;
}

.man > img{
    width: 40%;
    margin-left: 130px;
}


@media only screen and (max-width:787px)
{


    .landsec2{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 60px;
    }

    .landsec2title{
        color: white;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        font-size: 30px;
    }

    .sec2boxes{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 50px;
    }

    .sec2box1cont{
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sec2box1cont > img{
        width: 100%;
        max-width: 400px;
        height: auto;
        margin-bottom: 40px;
    }

    .sec2price{
        margin-top: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sec2price > img{
        width: 50%;
    }

    .bigcards{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        margin-top: 80px;
    }

    .bigcards > img{
        margin-bottom: 40px;
        width: 85%;
        max-width: 650px;
    }

    .sec3text{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 60px;
    }

    .sec3text > img{
        width: 70%;
    }


    .sec3textland{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 50px;
        /* margin-top: 60px; */
    }

    .sec3textland > img{
        width: 70%;
    }

    .reportbtn{
        cursor: pointer;
        width: 40% !important;
        margin-top: 60px;
    }

    .man{
        width: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: 80px;
    }

    .man > img{
        width: 80%;
        margin-left: 68px;
    }

    .gogoleimage{
        position: absolute !important;
        width: 100px  !important;
        height: auto  !important;
        bottom: 50px;
        left: 30px;
    }
    
    .appleimage{
        position: absolute !important;
        width: 100px  !important;
        height: auto  !important;
        bottom: 95px;
        left: 30px;
    }
    
}