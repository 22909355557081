@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,600&display=swap');

.landsec1{
    /* background-image: url('../../Asserts/section1bg.png');
    background-position: center center;
    background-size: cover; */
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

}

.landsec1header{
    color: white;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 80px;
    letter-spacing: 2px;

}

.landsec1subtitile{
    color:white;
    text-align: center;
    font-weight: 300;
    font-size: 30px;
    margin-bottom: 50px;
}

@media only screen and (max-width:787px)
{


    .landsec1{
        /* background-image: url('../../Asserts/section1bg.png');
        background-position: center center;
        background-size: cover; */
        height: 70vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    
    }

    .landsec1 > img{
        width: 220px;
        margin-top: 30px;
    }

    .landsec1header{
        color: white;
        text-align: center;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 40px;
    
    
    }

    .landsec1subtitile{
        font-size: 20px;
    }

}