.App{
    background-image: url('./Asserts/Desktop\ -\ 11\ \(3\).png');
    background-position: top top;
    background-size: cover;
    /* height: 600vh; */
}

@media only screen and (max-width:767px) {
    .App{
        background-image: url('./Asserts/mobileBG.png');
        background-position: top top;
        background-size: cover;
        /* height: 600vh; */
    }
}